import Link from "next/link";

export default function BottomCard() {
  return (
    <section className="container max-w-screen-lg flex flex-wrap mx-auto pt-12 pb-16 md:pb-24">
      <div
        className="flex-grow bg-primary bg-bottom sm:bg-right bg-contain bg-no-repeat py-16 md:py-20 lg:py-24 px-4 md:px-12 rounded-2xl mx-auto"
        style={{
          backgroundImage: "url(/images/homepage/bottom-card-illustration.svg)",
        }}
      >
        <div>
          <header className="text-2xl lg:text-3xl text-white font-bold pb-8 sm:w-2/3 lg:w-1/2 ">
            Are you ready to start making money online and grow your business?
          </header>
          {/* <Link> */}
            <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="bg-white px-4 md:px-6 py-2 md:py-3 text-primary shadow hover:shadow-lg rounded tracking-wide font-bold">
              START NOW
            </a>
          {/* </Link> */}
        </div>
        <div className="sm:hidden py-28 font-black rounded-xl mx-auto"></div>
      </div>
    </section>
  );
}
