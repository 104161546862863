import Link from "next/link";
import { logout } from "../../../libs/auth";

export default function UserAccountDropdown(props) {
  return (
    <aside className="dropdown self-center">
      <button
        type="button"
        className="flex items-center whitespace-nowrap text-sm rounded py-1 px-2 hover:text-primary"
        id="profile-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <img src="/icon/profile.svg" alt="p" className="pr-2" />
        <div>{props.firstName}</div>
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className="dropdown-menu  absolute hidden origin-top w-4-0 rounded shadow-xl bg-white ring-1 ring-dark ring-opacity-5 focus:outline-none w-32"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        {props.profileLinks.map((link, index) => (
          <Link href={link.url} key={index}>
            <a
              rel="noopener noreferrer"
              className="text-gray-700 block px-6 py-2 text-xs hover:bg-primary-lightest hover:text-primary"
            >
              {link.title}
            </a>
          </Link>
        ))}

        <div
          rel="noopener noreferrer"
          onClick={() => logout()}
          className="cursor-pointer block rounded m-2 px-6 py-2 text-xs text-white text-center bg-dark hover:bg-dark-light hover:text-primary-light"
        >
          LOGOUT
        </div>
      </div>
    </aside>
  );
}
