import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function FooterNewsletterForm() {
  const [sending, setSendingStatus] = useState(null);
  const [success, setSuccessStatus] = useState(null);
  const [error, setErrorStatus] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const sendRequest = (data) => {
    const payload = data;
    setSuccessStatus(false);
    setSendingStatus(true);

    axios
      .post(`${process.env.BACKEND_URL}/newsletter-subscribe`, {
        ...payload,
        platform: "seller",
      })
      .then((response) => {
        setSuccessStatus(true);
      })
      .catch((error) => {
        setErrorStatus(true);
      })
      .finally(() => {
        setSendingStatus(false);
      });
  };

  return (
    <>
      <div className="text-2xl lg:text-3xl font-black">
        Be a member <br /> of our community 🎉
      </div>
      <div className="text-gray-100 text-sm leading-relaxed py-4 md:py-8">
        We’d make sure you’re always the first to know what’s happening at
        Vasiti.
      </div>

      <form onSubmit={(e) => e.preventDefault()} id="vasiti-newsletter-form">
        <section className="flex items-center text-sm bg-dark-light rounded p-1">
          <input
            id="vasiti-newsletter-input"
            placeholder="Enter Your email"
            className="bg-dark-light w-full h-full text-white rounded px-4 py-2 mr-1 focus:outline-none"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          <button
            id="vasiti-newsletter-button"
            className="flex space-x-3 text-dark text-xs bg-white hover:bg-primary-light font-medium rounded px-3 py-2"
            onClick={handleSubmit(sendRequest)}
          >
            <span>SUBSCRIBE</span>
            {sending && (
              <svg
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className="bi bi-hourglass-split h-4 w-4 text-primary ml-2"
              >
                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
              </svg>
            )}

            {success && (
              <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="bi bi-check2-all h-4 w-4 text-primary ml-2"
              >
                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
              </svg>
            )}
          </button>
        </section>

        {(error || errors.email || success) && (
          <div className="text-xs italic pt-1">
            {success ? (
              <div className="text-white">
                Thank you for subscribing to our newsletter🎉
              </div>
            ) : (
              <>
                {errors.email ? (
                  <div className="text-yellow-300">
                    {errors.email?.type === "required" &&
                      "Please enter your email address"}
                    {errors.email?.type === "pattern" &&
                      "Please enter a valid email address"}
                  </div>
                ) : (
                  error && (
                    <div className="text-red-600">
                      An error occurred, please try again.
                    </div>
                  )
                )}
              </>
            )}
          </div>
        )}
      </form>
    </>
  );
}
