import Link from "next/link";

export default function HomeHeroSection() {
  return (
    <section>
      <div className="w-full sm:py-8 bg-gradient-to-b from-primary-lightest to-floral">
        <div className="container sm:flex max-w-screen-xl px-8 md:px-4 h-full">
          <div className="w-full sm:w-3/5 md:w-3/5 lg:w-1/2 py-8 md:pt-12 lg:pt-16 md:pb-32 lg:pb-44 h-full">
            <header className="tracking-tight font-extrabold text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
              <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-accent py-1">
                Sell Faster, Make Money and Grow Your Business
              </h1>
            </header>
            <article className="text-sm md:text-base text-gray-600 leading-10 mt-5 md:mt-5 lg:mx-0">
              <div className="leading-relaxed">
                Are you a small/medium business? Do you want to sell to
                thousands of customers across Nigeria? Do you want a free online
                store with all the resources your business needs?
              </div>
            </article>

            <div className="mt-5 sm:mt-8">
                <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="py-2 px-4 md:p-6 md:py-3 text-white font-semibold rounded shadow-md hover:shadow-xl bg-primary focus:outline-none">
                  Get Started
                </a>
            </div>
          </div>

          <div
            className="flex-grow min-h-full bg-contain bg-bottom sm:bg-right bg-no-repeat sm:py-0 py-36"
            style={{ backgroundImage: "url(/images/homepage/hero-bg.svg)" }}
          ></div>
        </div>
      </div>
    </section>
  );
}
