import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function WhatsappContactButton(props) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="fixed right-4 bottom-12 rounded-full z-40 text-7xl"
      href={
        props.mobile
          ? "whatsapp://send?phone=2348146439559"
          : "https://web.whatsapp.com/send?phone=2348146439559"
      }
    >
      <img src="/icon/whatsapp.png" className="h-14 w-14" alt="Whatsapp" />
    </a>
  );
}
