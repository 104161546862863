import { Button } from "@material-ui/core";
import HomeSubtitle from "../_partials/HomeSubtitle";

export default function BannerWidget() {
  const reasons = [
    {
      icon: "/images/homepage/icon/005.svg",
      title: "Create a Free Account",
      details:
        "Sign up and create a Vasiti account, provide the necessary details and follow the steps to create your store.",
    },
    {
      icon: "/images/homepage/icon/006.svg",
      title: "Update Your Store",
      details:
        "Upload clear and exact images of all the products that you have available. The more you upload, the more you sell.",
    },
    {
      icon: "/images/homepage/icon/008.svg",
      title: "Fulfill Your Orders",
      details:
        "People would buy products from your store. You will be notified and you must ensure the item is ready for immediate pick up and delivery.",
    },
    {
      icon: "/images/homepage/icon/007.svg",
      title: "Grow Your Profile and Gain More Benefits",
      details:
        "As you efficiently grow your business on Vasiti, we would provide you with further support via funding, market insights etc.",
    },
  ];
  return (
    <nav className="container mx-auto max-w-screen-xl py-4 md:py-8">
      <HomeSubtitle className="pb-4 lg:pb-8">How It Works</HomeSubtitle>

      <section className="p-4 lg:p-12 bg-white rounded">
        <section className=" ">
          {reasons.map((reason, i) => (
            <article className="group py-2" key={i}>
              <div className="flex items-center rounded-lg p-4 md:p-6 lg:p-8 shadow-lg group-hover:shadow-xl transition duration-150 ease-in-out transform hover:scale-105">
                <nav className="text-2xl lg:text-4xl text-gray-500 font-black group-hover:text-primary">
                  {i + 1}
                </nav>
                <div className="flex-grow pl-4 md:px-8">
                  <header className="font-bold pb-2 text-base md:text-lg text-primary">
                    {reason.title}
                  </header>
                  <div className="text-sm md:text-base text-gray-600 md:w-2/3">
                    {reason.details}
                  </div>
                </div>
                <div className="hidden md:inline pl-4">
                  <img
                    src={reason.icon}
                    className="w-12 h-12 md:w-16 md:h-16"
                    alt="icon"
                  />
                </div>
              </div>
            </article>
          ))}
        </section>
      </section>
    </nav>
  );
}
