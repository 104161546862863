import Link from "next/link";
import { logout } from "../../../libs/auth";
import { ChevronRight, Close, Lock } from "@material-ui/icons";
import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";

export default function NavigationDrawer(props) {
  return (
    <div className="sm:hidden">
      <Drawer
        open={props.status}
        onClose={() => props.setStatus(!props.status)}
      >
        <List
          style={{ width: "85vw" }}
          onClick={() => props.setStatus(!props.status)}
          onKeyDown={() => props.setStatus(!props.status)}
          className="h-full flex flex-col justify-between min-h-screen max-h-screen"
        >
          <section>
            <nav className="flex items-center border-b border-floral px-2 py-1">
              <span
                onClick={() => props.setStatus(!props.status)}
                className="text-dark hover:bg-primary-lightest p-3 rounded mr-4"
              >
                <Close />
              </span>

              <Link href="/">
                <a>
                  <img src="/logo.svg" alt="seller-logo" />
                </a>
              </Link>
            </nav>

            <section>
              <Link href={!!props.firstName ? "/dashboard" : "/login"}>
                <ListItem button>
                  <ListItemText>
                    <div className="flex items-center justify-between font-bold text-sm text-primary">
                      <div className="flex items-center capitalize">
                        <img
                          src="/icon/nav-drawer/person.svg"
                          className="h-auto w-4 mr-4"
                          alt="icon"
                        />
                        {props.firstName || "Login"}
                      </div>
                      <div className="flex items-center text-dark-light text-xs font-normal">
                        {!!props.firstName ? "Your Profile" : "My Profile"}
                        <ChevronRight fontSize="inherit" />
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              </Link>

              <section className="border-t border-floral">
                {props.links.map((link, index) => {
                  return (
                    <nav key={index}>
                      {link.children ? (
                        <ListItem>
                          <ListItemText>
                            <div className="flex items-center font-bold text-sm text-primary py-2">
                              <img
                                src={link.icon}
                                className="h-auto w-4 mr-4"
                                alt="icon"
                              />
                              {link.title}
                            </div>

                            <div className="pl-6">
                              {link.children.map((child, index) => (
                                <Link href={child.url} key={index}>
                                  <a className="block p-2 text-dark-light text-xs first:font-normal hover:bg-floral rounded">
                                    <div className="flex items-center justify-between">
                                      <div>{child.title}</div>
                                      {child.locked && (
                                        <div>
                                          <Lock fontSize="inherit" />
                                        </div>
                                      )}
                                    </div>
                                  </a>
                                </Link>
                              ))}
                            </div>
                          </ListItemText>
                        </ListItem>
                      ) : (
                        <Link href={link.url} key={index}>
                          <ListItem button>
                            <ListItemText>
                              <div className="flex items-center font-bold text-sm text-primary">
                                <img
                                  src={link.icon}
                                  className="h-auto w-4 mr-4"
                                  alt="icon"
                                />
                                {link.title}
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Link>
                      )}
                    </nav>
                  );
                })}
              </section>
            </section>
          </section>

          <div className="pb-8">
            {!!props.firstName ? (
              <ListItem button onClick={() => logout()}>
                <div className="flex items-center font-bold text-sm text-dark">
                  <img
                    src="/icon/nav-drawer/logout.svg"
                    className="h-auto w-4 mr-4"
                    alt="icon"
                  />
                  <span>LOGOUT</span>
                </div>
              </ListItem>
            ) : (
                <div className="px-8 py-4">
                    <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="font-medium text-center text-sm border border-dark rounded p-3 hover:bg-primary hover:border-white hover:text-white block">
                      Create an account
                    </a>
                </div>
            )}
          </div>
        </List>
      </Drawer>
    </div>
  );
}
