import HomeSubtitle from "../_partials/HomeSubtitle";

export default function WhySellOnVasiti() {
  const reasons = [
    {
      icon: "/images/homepage/icon/001.svg",
      title: "Faster Sales",
      details:
        "Vasiti puts your business in front of thousands of customers across Nigeria so you can sell quickly and make money.",
    },
    {
      icon: "/images/homepage/icon/002.svg",
      title: "100% Free",
      details:
        "Setting up your store and selling on Vasiti is Free. However, you will be charged a small commission after every sale.",
    },
    {
      icon: "/images/homepage/icon/003.svg",
      title: "Easy Procurement",
      details:
        "Buy everything you need directly from major distributors and wholesalers. From products you sell, to packaging materials, to souvenirs and more.",
    },
    {
      icon: "/images/homepage/icon/004.svg",
      title: "Access to Funds",
      details:
        "Vasiti will provide your business with all the funding it needs to grow. We’ll do this by providing you with very low-interest loans.",
    },
  ];

  return (
    <section className="container checked:mx-auto max-w-screen-xl py-4 md:py-8">
      <HomeSubtitle>Why should you sell on Vasiti?</HomeSubtitle>

      <nav className="container py-8 lg:p-12 bg-white rounded">
        <section className="lg:flex flex-wrap">
          {reasons.map((reason, i) => (
            <article
              className={`${
                i % 2 ? "lg:pt-8 lg:pb-0" : "lg:pb-8 lg:pt-0"
              } lg:w-1/2 sm:px-4 py-2`}
              key={i}
            >
              <div className="sm:flex flex-row-reverse bg-floral sm:bg-primary-light text-center sm:text-left p-4 md:p-8 rounded-lg sm:shadow hover:shadow-lg h-full">
                <div className="pb-4 md:pb-0 md:pl-4">
                  <div className="flex justify-center items-center bg-white h-12 w-12 lg:w-16 lg:h-16 rounded-full mx-auto">
                    <img
                      src={reason.icon}
                      className=" h-12 w-12 lg:w-16 lg:h-16"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="flex-grow">
                  <header className="font-bold pb-4 md:text-lg">
                    {reason.title}
                  </header>
                  <div className="text-gray-600 ">{reason.details}</div>
                </div>
              </div>
            </article>
          ))}
        </section>
      </nav>
    </section>
  );
}
