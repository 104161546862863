import Link from "next/link";
import React from "react";

const links = [
  {
    title: "MARKETPLACE",
    url: "https://marketplace.vasiti.com/",
  },
  {
    title: "WHOLESALE CENTER",
    url: "https://wholesale.vasiti.com",
  },
  {
    title: "SELLER CENTER",
    url: "/",
    active: true,
  },
  {
    title: "LOGISTICS NETWORK",
    url: "https://logistics.vasiti.com/",
  },
  {
    title: "EVENTS",
    url: "https://events.vasiti.com/",
  },
];

export default function StickyTopBar() {
  return (
    <div className="hidden bg-white text-center border sm:block text-xs py-2">
      {links.map((link, index) => {
        return (
          <Link href={link.url} key={index}>
            <a
              className={`${
                link.active
                  ? "text-dark font-medium"
                  : "text-gray-400 hover:text-primary"
              } rounded hover:bg-floral px-4 py-2 mx-2`}
              target={link.active ? "_self" : "_blank"}
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          </Link>
        );
      })}
    </div>
  );
}
