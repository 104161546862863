import { useMediaQuery } from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import Link from "next/link";

export default function BannerWidget() {
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <div className="container max-w-screen-xl mx-auto py-12 md:py-16 lg:py-20">
      <nav className="sm:flex flex-row-reverse mx-auto rounded min-w-full">
        <section className="flex justify-center items-center md:w-3/5 lg:w-1/2 sm:px-2">
          <div className="py-8 md:w-5/6">
            <h2 className="text-2xl md:text-4xl lg:text-5xl font-black text-dark pb-4 md:pb-8 lg:pb-12">
              Discover the better way to grow your business.
            </h2>
            <article className="text-sm md:text-base text-gray-600 leading-loose">
              <div className="leading-relaxed">
                Vasiti provides end-to-end solutions to the problems SMEs face
                while trying to make money online and grow their business. Our
                solution is simple , user-friendly and free.
              </div>
            </article>

            <div className="pt-4 lg:pt-8">
                <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="text-dark hover:text-primary text-base md:text-lg font-bold">
                  Sell Now <ArrowRightAlt size="large" />
                </a>
            </div>
          </div>
        </section>
        <section className="md:w-2/5 lg:w-1/2 bg-white rounded">
          <img
            src="/images/homepage/banner-1.gif"
            alt="banner"
            className="w-auto h-full"
          />
        </section>
      </nav>
    </div>
  );
}
