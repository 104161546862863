import React, { useState } from "react";
import FooterNewsletterForm from "./FooterNewsletterForm";

export default function TheFooter() {
  const LINKS = [
    {
      title: "Company",
      links: [
        { title: "About Us", slug: "https://vasiti.com/about" },
        { title: "Term of Use", slug: "https://vasiti.com/terms-of-use" },
        { title: "Privacy Policy", slug: "https://vasiti.com/privacy-policy" },
        { title: "Press & Media", slug: "#press-media" },
        { title: "Testimonials", slug: "https://wholesale.vasiti.com/stories" },
      ],
    },
    {
      title: "Products",
      links: [
        { title: "Marketplace", slug: "https://marketplace.vasiti.com" },
        { title: "Seller Center", slug: "https://seller.vasiti.com" },
        { title: "Wholesale Center", slug: "https://wholesale.vasiti.com" },
        { title: "Logistics Network", slug: "https://logistics.vasiti.com" },
        { title: "Events", slug: "https://events.vasiti.com" },
      ],
    },
    {
      title: "Career",
      links: [
        {
          title: "Become a Campus Rep",
          slug: "https://wholesale.vasiti.com/Campus-Rep",
        },
        {
          title: "Become a Vasiti Influencer",
          slug: "https://wholesale.vasiti.com/Campus-Rep-Signup",
        },
        {
          title: "Join our Team",
          slug: "https://vasiti.com/contact#/career",
        },
      ],
    },
    {
      title: "Get in touch",
      links: [
        { title: "Contact us", slug: "https://vasiti.com/contact" },
        {
          title: "Partner with us",
          slug: "https://vasiti.com/contact#/partner-with-us",
        },
        { title: "Return & Refund", slug: "#/return-refund" },
        { title: "Help/FAQs", slug: "https://vasiti.com/faq" },
      ],
    },
  ];

  const SOCIAL = [
    {
      title: "Facebook",
      icon: "https://www.vasiti.com/icon/footer/fb.png",
      href: "https://www.facebook.com/VasitiMarketplace/",
    },
    {
      title: "Twitter",
      icon: "https://www.vasiti.com/icon/footer/tw.png",
      href: "https://twitter.com/vasitidotcom",
    },
    {
      title: "Instagram",
      icon: "https://www.vasiti.com/icon/footer/ig.png",
      href: "https://www.instagram.com/vasitidotcom/",
    },
    {
      title: "LinkedIn",
      icon: "https://www.vasiti.com/icon/footer/in.png",
      href: "https://www.linkedin.com/company/vasiti.com",
    },
  ];

  const vasiti = {
    email: { title: "contact@vasiti.com", href: "mailto:contact@vasiti.com" },
    phone: { title: "+234 814 643 9559", href: "tel:+2348146439559" },
  };

  const [hideIfMobile, setHideIfMobile] = useState(true);

  return (
    <footer className="bg-dark text-white py-8 md:py-12 mt-12">
      <section className="container max-w-screen-xl">
        <section className="w-full flex flex-wrap">
          <div
            className="w-full hidden sm:inline-block sm:w-1/2 py-52 bg-no-repeat bg-contain bg-center -mt-28 md:-mt-20"
            style={{
              backgroundImage:
                "url(https://www.vasiti.com/icon/footer/subscribe-banner.svg)",
            }}
          ></div>
          <div className="w-full sm:w-1/2 lg:w-1/3 py-4 md:py-8lg:pl-8">
            <FooterNewsletterForm />
          </div>
        </section>

        <div className="md:hidden w-full text-white text-xs py-6">
          <div className="flex items-center py-2">
            <img
              src="https://www.vasiti.com/icon/footer/email.svg"
              className="h-6 w-auto mr-3"
              alt="email"
            />
            <a href={vasiti.email.href}>{vasiti.email.title}</a>
          </div>
          <div className="flex items-center py-2">
            <img
              src="https://www.vasiti.com/icon/footer/phone.svg"
              className="h-6 w-auto mr-3"
              alt="phone"
            />
            <a href={vasiti.phone.href}>{vasiti.phone.title}</a>
          </div>
        </div>
      </section>

      <nav className="md:hidden font-medium text-xs text-center border-t border-dark-light pt-4">
        <div
          onClick={() => {
            setHideIfMobile(!hideIfMobile);
          }}
          className="flex justify-center items-center hover:text-primary-light cursor-pointer p-4"
        >
          <div>Show {hideIfMobile ? "More" : "Less"} Option</div>
          <img
            src="https://www.vasiti.com/icon/footer/arrow-up.svg"
            className={`${
              hideIfMobile && "transform rotate-180"
            } w-8 h-auto ml-4`}
            alt="arrow-toggle"
          />
        </div>
      </nav>

      <section
        className={`${
          hideIfMobile ? "hidden md:block" : "block"
        } container max-w-screen-xl`}
      >
        <section className="w-full flex flex-wrap py-4 md:py-12">
          {LINKS.map((LINK, i) => (
            <div className="w-1/2 lg:w-1/5 px-2 md:px-4 pt-4" key={i}>
              <div className="text-base text-gray-100 font-bold py-4">
                {LINK.title}
              </div>
              {LINK.links.map((link, j) => (
                <a
                  className="block text-xs font-medium text-gray-300 hover:text-gray-100  py-2 my-1"
                  href={link.slug}
                  key={j}
                >
                  {link.title}
                </a>
              ))}
            </div>
          ))}
          <div className="flex-grow px-2 md:px-4 pt-4">
            <div className="font-bold py-4">Join us on Social Media</div>
            <div className="flex py-4">
              {SOCIAL.map((link, k) => (
                <a
                  key={k}
                  href={link.href}
                  className="mr-2 p-1"
                  alt={link.title.slice(0, 2)}
                >
                  <img src={link.icon} className="h-6" alt="icon" />
                </a>
              ))}
            </div>
          </div>
        </section>
      </section>

      <section className={hideIfMobile ? "hidden md:block" : "block"}>
        <div className="container max-w-screen-xl text-gray text-xs  border-dark-light border-t text-center pt-8">
          Copyright &copy; Vasiti.com {new Date().getFullYear()}. All rights
          reserved
        </div>
      </section>
    </footer>
  );
}
