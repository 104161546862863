import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";
import BannerWidget from "../components/HomePage/BannerWidget";
import WhySellOnVasiti from "../components/HomePage/WhySellOnVasiti";
import HowItWorks from "../components/HomePage/HowItWorks";
import WhatYouShouldKnow from "../components/HomePage/WhatYouShouldKnow";
import TheFooter from "../components/_partials/TheFooter";
import HomeHeroSection from "../components/HomePage/HomeHeroSection";
import BottomCard from "../components/_partials/BottomCard";
import NavigationBar from "../components/_partials/Navigation/NavigationBar";
import WhatsappContactButton from "../components/_partials/Navigation/WhatsappContactButton";

// CSS Styles
export default function Home() {
  const [hasMounted, setHasMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setHasMounted(true);
    setIsMobile(window.orientation > -1);
  }, [setHasMounted, setIsMobile]);

  return (
    <>
      <Head>
        <title>
          Vasiti Seller Center | Sell Faster, Make Money and Grow Your Business
        </title>
        <link rel="canonical" href="http://seller.vasiti.com" />
        <meta
          name="description"
          content={`Everything you need to make money online and grow your business. Sell to thousands of
customers across Nigeria. Get a free online store, manage your orders, inventory and logistics.
Vasiti will help you find customers to sell to and find trusted wholesalers to buy in bulk from. Get
low-interest loans to grow your business. The best online selling platform for small businesses,
online vendors and retailers who want to earn more money and grow their business.`}
        />
        <meta
          name="keywords"
          content={`sell online, seller, sell faster, find customers, retailer, sme, instagram vendor,
facebook vendor, whatsapp vendor, twitter vendor, jiji, jumia, konga, business, online business,
money, make money, advertise goods, trusted seller, startup, loans, sell it, online store, free
online store, make money online, earn money online, ways to make money online, make
money from home, Vasiti Seller Center, Vasiti.com, find customers, sell to customers, business
growth, customer service, find customers, find wholesalers, buy in bulk, student entrepreneur,
small business owner, sell on campus, make money on campus, ecommerce business, upload
products, upload services, sell products, business to business, make money fast, make money fast
today, business marketing, ways to earn money online, quick money, Vasiti seller, online earning
sites, business site, online earning websites, online money making sites, starting an online
business, money fast, money making sites, make money online legit, to make money, online
business free, earn fast, online successful business, best businesses online, ecommerce business,
retailers, retail store, selling products online, best product to sell online, online sellers, online
selling platform, Vasiti sellers, online business free, online successful business, fast online
money making, retail store, starting an online business, internet business, best selling online
products, online successful business, products, services,`}
        />

        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-5SNY33XJ8Z"
        />
        <Script strategy="lazyOnload" id="gtag">
          {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-5SNY33XJ8Z');`}
        </Script>
      </Head>

      {hasMounted && <NavigationBar />}

      <main style={{ backgroundColor: "#f8f8f8" }} className="overflow-hidden">
        <HomeHeroSection />
        <BannerWidget />
        <WhySellOnVasiti />
        <HowItWorks />
        <WhatYouShouldKnow />
        <BottomCard />
      </main>

      <footer>
        <WhatsappContactButton mobile={!!isMobile} />
        <TheFooter mobile={!!isMobile} />
      </footer>
    </>
  );
}
