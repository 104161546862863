import StopIcon from "@material-ui/icons/Stop";
import HomeSubtitle from "../_partials/HomeSubtitle";

export default function WhatYouShouldKnow() {
  const reasons = [
    {
      title: "We Want Happy Customers",
      details:
        "We want our buyers to be very happy so we expect you to sell only authentic products, upload exact images and use an accurate description.",
    },
    {
      title: "We Offer Super-Fast Delivery",
      details:
        "Please ensure products are available on standby before you upload to the site so it can quickly be picked up and delivered. ",
    },
    {
      title: "We Want Your Business to Grow",
      details:
        "Vasiti will provide all the resources you need to succeed online so your business can grow faster, make more money and be efficient. ",
    },
  ];
  return (
    <section className="py-8 mb-8">
      <header className="container max-w-screen-xl pb-">
        <HomeSubtitle>What You Should Know</HomeSubtitle>
      </header>

      <section className="bg-primary-light">
        <div className="container max-w-screen-xl">
          <div className="md:flex flex-row-reverse">
            <div className="flex-grow py-4 w-full">
              <div
                className="py-24 md:py-48 bg-no-repeat bg-center bg-contain"
                style={{
                  backgroundImage:
                    "url(/images/homepage/performance-overview.svg)",
                }}
              ></div>
            </div>

            <div className="p-4 md:p-8 lg:p-12">
              <section style={{ listStyleType: "square" }}>
                {reasons.map((reason, i) => (
                  <article className="flex pb-8 pr-4" key={i}>
                    <StopIcon fontSize="small" color="primary" />
                    <div className="flex-grow pl-4">
                      <h4 className="font-bold pb-2 text-base md:text-lg text-primary">
                        {reason.title}
                      </h4>
                      <div className="text-sm text-gray-600">
                        {reason.details}
                      </div>
                    </div>
                  </article>
                ))}
              </section>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
