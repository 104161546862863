import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { Menu, Close, Call, ChevronRight } from "@material-ui/icons";
import authGetter, { logout } from "../../../libs/auth";
import UserAccountDropdown from "./UserAccountDropdown";
import NavigationDrawer from "./NavigationDrawer";
import StickyTopBar from "./StickyTopBar";

const menuItems = {
  telephone: "+234 814 643 9559",
  links: [
    {
      title: "Products",
      icon: "/icon/nav-drawer/products.svg",
      children: [
        {
          title: "Marketplace Center",
          url: "https://marketplace.vasiti.com",
        },
        {
          title: "Wholesale Center",
          url: "https://wholesale.vasiti.com",
        },
        {
          title: "Seller Center",
          url: "https://seller.vasiti.com",
        },
        {
          title: "Events",
          url: "https://events.vasiti.com",
        },
        {
          title: "Services",
          url: "#",
          locked: true,
        },
        {
          title: "Internship",
          url: "#",
          locked: true,
        },
      ],
    },
    {
      title: "About Us",
      url: "https://vasiti.com/about",
      icon: "/icon/nav-drawer/info.svg",
    },
    {
      title: "Stories",
      url: "https://wholesale.vasiti.com/stories",
      icon: "/icon/nav-drawer/stories.svg",
    },
    {
      title: "Contact Us",
      url: "https://vasiti.com/contact",
      icon: "/icon/nav-drawer/phone.svg",
    },
  ],
};

const profileLinks = [
  {
    url: "/dashboard",
    title: "Dashboard",
  },
  {
    url: "/dashboard/settings",
    title: "My Account",
  },
  {
    url: "/dashboard/referrals",
    title: "Refer and Earn",
  },
];

export default function NavigationBar() {
  const router = useRouter();
  const auth = authGetter();
  const [drawerStatus, setDrawerStatus] = useState(false);

  return (
    <>
      <StickyTopBar />
      <header className="flex-grow border-b bg-white sticky top-0 z-50 py-1">
        <div className="container max-w-screen-xl text-sm z-10">
          <nav className="flex justify-between">
            <div className="flex items-center">
              <div className="sm:hidden">
                <span
                  onClick={() => setDrawerStatus(true)}
                  className="text-dark hover:bg-primary-lightest p-3 rounded mr-1"
                >
                  <Menu />
                </span>
              </div>

              <Link href="/">
                <a>
                  <img className="h-10" src="/logo.svg" alt="seller-logo" />
                </a>
              </Link>
            </div>

            <div className="flex items-center">
              <NavigationDrawer
                firstName={auth?.user?.firstName || ""}
                links={menuItems.links}
                setStatus={setDrawerStatus}
                status={drawerStatus}
              />

              <div className="hidden sm:flex">
                {menuItems.links.map(
                  (link, index) =>
                    !link.children && (
                      <Link href={link.url} key={index}>
                        <a className="text-gray-700 block px-6 py-2 text-xs hover:bg-primary-lightest hover:text-primary rounded uppercase">
                          {link.title}
                        </a>
                      </Link>
                    )
                )}

                {!!!auth.user && (
                  <Link href="/login">
                    <a className="text-gray-700 block px-6 py-2 text-xs hover:bg-primary-lightest hover:text-primary rounded uppercase">
                      LOGIN
                    </a>
                  </Link>
                )}

                {!!auth?.user ? (
                  <UserAccountDropdown
                    firstName={auth.user.firstName}
                    profileLinks={profileLinks}
                  />
                ) : (
                    <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="text-center text-xs font-medium rounded bg-primary text-white shadow-sm hover:shadow-md px-4 py-2 ml-0.5">
                      SIGNUP
                    </a>
                )}
              </div>

              <div className="sm:hidden">
                <Link href={!!authGetter().user ? "/dashboard" : "/login"}>
                  <a className="cursor-pointer block rounded m-1 p-3 text-xs text-white text-center hover:bg-primary-lightest">
                    <img
                      src="/icon/profile-outline.svg"
                      className="h-6 w-6"
                      alt="p"
                    />
                  </a>
                </Link>
              </div>

              <Link href={`tel:${menuItems.telephone}`}>
                <a
                  className="flex items-center cursor-pointer rounded m-1 p-3 md:pr-0 sm:py-1 text-dark hover:text-primary hover:bg-primary-lightest"
                  href={`tel:${menuItems.telephone}`}
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icon/phone-outline.svg"
                    className="md:hidden h-6 w-6"
                    alt="phone"
                  />
                  <div className="hidden md:inline-flex items-center text-sm font-normal">
                    <Call className="md:pr-1" />
                    <div>{menuItems.telephone}</div>
                  </div>
                </a>
              </Link>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
